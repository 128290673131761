<template>
  <v-row>
    <v-col
      cols="12"
      class="py-0"
    >
      <span class="font-weight-bold text-body-2">9 Описание товаров</span>
    </v-col>
    <goods-description
      ref="description"
      v-model="item.description"
      :index="index"
      :item="info"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import GoodsDescription from "./description/index.vue";
import cloneDeep from "lodash.clonedeep";
import highlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";

export default {
  components: { GoodsDescription },
  mixins: [highlightFieldWareSector],
  props: {
    index: {
      required: true,
      type: Number,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    info: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    item: {
      id: "",
      declaration_id: "",
      description: "",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
      shipmentIndex:'uvr/getShipmentIndex'
    }),
    selectedWares(){
      return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    }
  },
  watch: {
    selectedWares: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  methods: {
    setFields() {
      const ware = cloneDeep(this.selectedWares[this.index]);
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
    },
    updateEditableGoodsItem() {
      this.$emit("update:info", this.item);
      if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    },
  },
};
</script>

<style scoped>
th {
  font-size: 10px !important;
}
td {
  font-size: 12px !important;
}
.v-divider {
  border-style: dashed;
}
</style>
