<template>
  <v-col cols="2">
    <label> 41 Доп. единица </label>
    <div class="d-flex mb-2">
      <input-numeric
        ref="add_measure_unit_quantity"
        v-model="item.add_measure_unit_quantity"
        :fraction="2"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
        disabled
        background-color="grey lighten-2"
      />
      <v-autocomplete
        ref="add_measure_unit_digit"
        v-model="item.add_measure_unit_digit"
        auto-select-first
        item-text="text"
        item-value="code"
        :items="measure_units"
        outlined
        dense
        hide-details="auto"
        disabled
        background-color="grey lighten-2"
        :filter="filterBySearchField"
      />
    </div>

    <label> Специфическая ед.изм.</label>
    <div class="d-flex mb-2">
      <input-numeric
        ref="specific_measure_unit_quantity"
        v-model="item.specific_measure_unit_quantity"
        :fraction="2"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
        disabled
        background-color="grey lighten-2"
      />
      <v-autocomplete
        ref="specific_measure_unit_digit"
        v-model="item.specific_measure_unit_digit"
        auto-select-first
        item-text="text"
        item-value="code"
        :items="measure_units"
        outlined
        dense
        hide-details="auto"
        disabled
        background-color="grey lighten-2"
        :filter="filterBySearchField"
      />
    </div>

    <label> Подакцизный товар </label>
    <div class="d-flex mb-2">
      <input-numeric
        ref="excise_measure_unit_quantity"
        v-model="item.excise_measure_unit_quantity"
        :fraction="2"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
        disabled
        background-color="grey lighten-2"
      />
      <v-autocomplete
        ref="excise_measure_unit_digit"
        v-model="item.excise_measure_unit_digit"
        auto-select-first
        item-text="text"
        item-value="code"
        :items="measure_units"
        outlined
        dense
        hide-details="auto"
        disabled
        background-color="grey lighten-2"
        :filter="filterBySearchField"
      />
    </div>
  </v-col>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { mapGetters } from "vuex";
import {
  tnvedCodeChanged,
  addPromise,
  exciseChanged,
  updateGoodParameters,
} from "@/events/statistics";
import { normalizeFloat } from "@/helpers/math";
import cloneDeep from "lodash.clonedeep";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import { getMeasureUnitShortName } from '@/helpers/catalogs'
import InputNumeric from "@/components/ui/input-numeric.vue";

export default {
  components: {InputNumeric},
  mixins: [filterBySearchFieldMixin, HighlightFieldWareSector],
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      required: true,
      type: Object,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    item: {
      id: "",
      add_measure_unit_digit: "",
      add_measure_unit_letter: "",
      add_measure_unit_quantity: "",
      specific_measure_unit_digit: "",
      specific_measure_unit_letter: "",
      specific_measure_unit_quantity: "",
      excise_measure_unit_digit: "",
      excise_measure_unit_letter: "",
      excise_measure_unit_quantity: "",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
      measure_units: "catalogs/getNsiMeasureUnits",
      shipmentIndex:'uvr/getShipmentIndex'
    }),
    selectedWares(){
      return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    }
  },
  watch: {
    selectedWares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    item: {
      handler() {
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeChanged.subscribe(this.onCodeChange);
    exciseChanged.subscribe(this.onExciseChange);
    updateGoodParameters.subscribe(this.onParamsChange);
  },
  beforeDestroy() {
    tnvedCodeChanged.unsubscribe();
    exciseChanged.unsubscribe();
    updateGoodParameters.unsubscribe();
  },
  methods: {
    replaceFloatNumbers({ value, prop, afterPoint = null }) {
      this.item[prop] = normalizeFloat({ value, afterPoint });
    },
    onParamsChange({ id, payload }) {
      if (id === this.info.id) {
        const { add_measure_unit_quantity } = payload;
        this.item.add_measure_unit_quantity = add_measure_unit_quantity;
        this.readyToUpdate();
      }
    },
    onExciseChange({ id, measureUnitDigit }) {
      if (id === this.info.id) {
        this.item.excise_measure_unit_digit = measureUnitDigit;
        this.readyToUpdate();
      }
    },
    onCodeChange({ id, code }) {
      if (id === this.info.id) {
        if (code) {
          const promise = new Promise((resolve, reject) => [
            Promise.all([
              this.$store.dispatch("tnved/fetchAddInfoByCode", code),
              this.$store.dispatch("tnved/fetchSpecificMeasureUnit", code),
            ])
              .then(([info, specific]) => {
                //info
                const { add_unit_measure } = info.data;
                this.item.add_measure_unit_digit = add_unit_measure;
                if (!add_unit_measure) this.item.add_measure_unit_quantity = "";

                //specific
                const { code, short_description } = specific.data;
                this.item.specific_measure_unit_digit = code;
                this.item.specific_measure_unit_letter = short_description;
                if (!code) this.item.specific_measure_unit_quantity = "";

                this.$emit("measure-changed", {
                  id: this.item.id,
                  value: this.item.add_measure_unit_digit,
                });
                resolve();
              })
              .catch((err) => reject(err)),
          ]);
          addPromise.trigger({ id: this.info.id, promise });
        } else {
          const promise = new Promise((resolve) => {
            this.item.add_measure_unit_digit = null;
            this.item.add_measure_unit_quantity = null;
            this.$emit("measure-changed", {
              id: this.item.id,
              value: this.item.add_measure_unit_digit,
            });
            resolve();
          });
          addPromise.trigger({ id: this.info.id, promise });
        }
      }
    },
    updateEditableGoodsItem() {
      const payload = {
        ...this.item,
        add_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.add_measure_unit_digit),
        specific_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.specific_measure_unit_digit),
        excise_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.excise_measure_unit_digit)
      }
      this.$emit("update:info", payload);

    },
    setFields() {
      const ware = cloneDeep(this.selectedWares[this.index]);
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
    },
    readyToUpdate() {
      this.$emit("update:visited", true);
      this.$emit("update:has-changes", true);
    },
  },
};
</script>
