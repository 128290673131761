import { render, staticRenderFns } from "./uvr-copy-field-modal.vue?vue&type=template&id=2cc997b4"
import script from "./uvr-copy-field-modal.vue?vue&type=script&lang=js"
export * from "./uvr-copy-field-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports