<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="statistics-box pa-4"
    :class="{'blocked-box' : disabledView}"
  >
    <v-row class="pb-4">
      <v-col>
        <div class="font-weight-bold d-flex align-center">
          Получатель
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1 mr-4"
                tabindex="-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
                @click="onMenuOpen"
              >
                mdi-tune
              </v-icon>
            </template>
            <v-list>
              <v-list-item> Поиск в справочнике </v-list-item>
              <v-list-item @click="show = true">
                Сохранить организацию в справочник
              </v-list-item>
            </v-list>
          </v-menu>
          <v-switch
            v-model="isIndividual"
            disabled
            hide-details="auto"
            dense
            color="#5CB7B1"
            @change="onIndividualChange"
          >
            <template #label>
              <label style="font-size: 12px">Физ. лицо</label>
            </template>
          </v-switch>
        </div>
      </v-col>
      <v-col
        cols="7"
        class="pb-0"
      >
        <div class="d-flex">
          <v-autocomplete
            v-model="search"
            :disabled="!isImport"
            class="ml-16"
            :loading="loading"
            :readonly="loading"
            :items="recipients"
            item-text="own_name"
            :filter="filterBySearchField"
            placeholder="Поиск в справочнике"
            auto-select-first
            return-object
            append-icon="mdi-magnify"
            :background-color="isImport? 'white': ' grey lighten-2'"
            dense
            outlined
            hide-details="true"
            tabindex="-1"
            @update:search-input="fetchExporters"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <label>
          Страна
          <v-autocomplete
            ref="country_letter"
            v-model="recipient_json.country_letter"
            auto-select-first
            outlined
            dense
            hide-details="auto"

            :filter="filterBySearchField"
            :items="countries"
            item-text="letterCode"
            item-value="letterCode"
            :disabled="!isImport"
            :background-color="isImport ? 'white' :'grey lighten-2'"
          />
        </label>
      </v-col>
      <v-col
        v-show="!isIndividual"
        cols="3"
        class="pb-0"
      >
        <label>
          УНП (ИНН И т.п.)
          <v-text-field
            ref="recipient_inn"
            v-model="recipient.recipient_inn"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            :disabled="!isImport"
            :background-color="isImport ? 'white' :'grey lighten-2'"
          />
        </label>
      </v-col>
      <v-col
        v-show="!isIndividual"
        cols="7"
        class="pb-0"
      >
        <label>
          Наименование организации
          <v-text-field
            ref="recipient_name"
            v-model="recipient.recipient_name"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            :disabled="!isImport"
            :background-color="isImport ? 'white' :'grey lighten-2'"
          />
        </label>
      </v-col>
      <v-col
        v-show="isIndividual"
        cols="3"
        class="pb-0"
      >
        <label>
          Номер документа
          <v-text-field
            ref="recipient_identity_doc_number"
            v-model="recipient.recipient_identity_doc_number"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        v-show="isIndividual"
        cols="3"
        class="pb-0 px-0"
      >
        <label>
          Дата документа
          <custom-date-picker
            ref="identity_doc_date"
            v-model="recipient_json.identity_doc_date"
            :visited.sync="fields_been_visited"
            :has-changes.sync="hasChanges"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        v-show="isIndividual"
        cols="4"
        class="pb-0"
      >
        <label>
          Личный номер
          <v-text-field
            ref="personal_number"
            v-model="recipient_json.personal_number"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        v-show="isIndividual"
        cols="4"
        class="pb-0"
      >
        <label>
          Фамилия
          <v-text-field
            ref="recipient_last_name"
            v-model="recipient.recipient_last_name"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        v-show="isIndividual"
        cols="4"
        class="pb-0"
      >
        <label>
          Имя
          <v-text-field
            ref="recipient_first_name"
            v-model="recipient.recipient_first_name"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        v-show="isIndividual"
        cols="4"
        class="pb-0"
      >
        <label>
          Отчество
          <v-text-field
            ref="recipient_middle_name"
            v-model="recipient.recipient_middle_name"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <label>
          Индекс
          <v-text-field
            ref="postal_index"
            v-model="recipient_json.postal_index"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        cols="5"
        class="pb-0"
      >
        <label>
          Область/Район
          <v-text-field
            ref="region"
            v-model="recipient_json.region"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="pb-0"
      >
        <label>
          Город/Деревня
          <v-text-field
            ref="city"
            v-model="recipient_json.city"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        cols="7"
        class="pb-0"
      >
        <label>
          Улица
          <v-text-field
            ref="street_house"
            v-model="recipient_json.street_house"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <label>
          Дом
          <v-text-field
            ref="house"
            v-model="recipient_json.house"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <label>
          Номер помещ.
          <v-text-field
            ref="room"
            v-model="recipient_json.room"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            disabled
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        v-if="!contacts.length"
        ref="contacts"
        class="mt-4"
        cols="8"
      >
        <v-btn
          disabled
          dense
          class="elevation-0"
          @click="addContactItem"
        >
          <v-icon>mdi-plus</v-icon> Контакт
        </v-btn>
      </v-col>
      <v-col
        v-if="contacts.length"
        ref="contacts"
        cols="8"
      >
        <single-contact
          v-for="(cont, index) in contacts"
          :ref="'cont_' + index"
          :key="index"
          :types="contact_types"
          :item="cont"
          :idx="index"
          disabled
          :contacts-length="contacts.length"
          @deleteContactItem="deleteContactItem"
          @addContactItem="addContactItem"
          @contactsUpdater="contactsUpdater"
        />
      </v-col>
      <v-col
        v-show="!isIndividual"
        cols="12"
        class="pb-0"
      >
        <div class="font-weight-bold d-flex align-center">
          Филиал
          <v-btn
            min-width="32px"
            text
            class="ml-1"
            @click="addition = !addition"
          >
            <v-icon>
              {{ !addition ? "mdi-chevron-down" : "mdi-chevron-up" }}
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col
        v-show="!isIndividual"
        cols="12"
      >
        <v-row>
          <v-col
            v-show="addition"
            cols="2"
            class="pb-0"
          >
            <label>
              Страна
              <v-autocomplete
                ref="branch_country_letter"
                v-model="recipient_json.branch_country_letter"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                :items="countries"
                item-text="letterCode"
                :filter="filterBySearchField"
                item-value="letterCode"
                disabled
                background-color="grey lighten-2"
              />
            </label>
          </v-col>
          <v-col
            v-show="addition"
            cols="3"
            class="pb-0"
          >
            <label>
              УНП (ИНН И т.п.)
              <v-text-field
                ref="recipient_branch_inn"
                v-model="recipient.recipient_branch_inn"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                disabled
                background-color="grey lighten-2"
              />
            </label>
          </v-col>
          <v-col
            v-show="addition"
            cols="7"
            class="pb-0"
          >
            <label>
              Наименование организации
              <v-text-field
                ref="recipient_branch_name"
                v-model="recipient.recipient_branch_name"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                disabled
                background-color="grey lighten-2"
              />
            </label>
          </v-col>
          <v-col
            v-show="addition"
            cols="3"
            class="pb-0"
          >
            <label>
              Индекс
              <v-text-field
                ref="branch_postal_index"
                v-model="recipient_json.branch_postal_index"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                disabled
                background-color="grey lighten-2"
              />
            </label>
          </v-col>
          <v-col
            v-show="addition"
            cols="5"
            class="pb-0"
          >
            <label>
              Область/Район
              <v-text-field
                ref="branch_region"
                v-model="recipient_json.branch_region"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                disabled
                background-color="grey lighten-2"
              />
            </label>
          </v-col>
          <v-col
            v-show="addition"
            cols="4"
            class="pb-0"
          >
            <label>
              Город/Деревня
              <v-text-field
                ref="branch_city"
                v-model="recipient_json.branch_city"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                disabled
                background-color="grey lighten-2"
              />
            </label>
          </v-col>
          <v-col
            v-show="addition"
            cols="7"
            class="pb-0"
          >
            <label>
              Улица
              <v-text-field
                ref="branch_street_house"
                v-model="recipient_json.branch_street_house"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                disabled
                background-color="grey lighten-2"
              />
            </label>
          </v-col>
          <v-col
            v-show="addition"
            cols="2"
            class="pb-0"
          >
            <label>
              Дом
              <v-text-field
                ref="branch_house"
                v-model="recipient_json.branch_house"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                disabled
                background-color="grey lighten-2"
              />
            </label>
          </v-col>
          <v-col
            v-show="addition"
            cols="3"
            class="pb-0"
          >
            <label>
              Номер помещ.
              <v-text-field
                ref="branch_room"
                v-model="recipient_json.branch_room"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                disabled
                background-color="grey lighten-2"
              />
            </label>
          </v-col>
          <v-col
            v-show="addition"
            cols="12"
            class="pb-0"
          >
            <div class="font-weight-bold d-flex align-center">
              Документ, подтверждающий включение лица в реестр УЭО (иностранный)
            </div>
          </v-col>
          <v-col
            v-show="addition"
            cols="2"
            class="pb-0"
          >
            <label>
              Страна
              <v-autocomplete
                ref="aeo_country_letter"
                v-model="recipient_json.aeo_country_letter"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                :filter="filterBySearchField"
                disabled
                background-color="grey lighten-2"
                :items="countries"
                item-text="letterCode"
                item-value="letterCode"
              />
            </label>
          </v-col>
          <v-col
            v-show="addition"
            cols="5"
            class="pb-0"
          >
            <label>
              Регистрационный номер
              <v-menu offset-x>
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="mx-1"
                    tabindex="-1"
                    small
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >mdi-tune</v-icon>
                </template>
                <v-list>
                  <v-list-item> Поиск в справочнике </v-list-item>
                </v-list>
              </v-menu>
              <v-autocomplete
                ref="aeo_reg_number"
                v-model="recipient_json.aeo_reg_number"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                class="mr-1"
                :items="aeos"
                item-value="unp"
                item-text="unp"
                disabled
                background-color="grey lighten-2"
              />
            </label>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="!branch_contacts.length && addition"
        ref="branch_contacts"
        cols="8"
      >
        <v-btn
          disabled
          dense
          class="elevation-0"
          @click="addContactItem('branch')"
        >
          <v-icon>mdi-plus</v-icon>
          Контакт
        </v-btn>
      </v-col>
      <v-col
        v-if="branch_contacts.length && addition"
        ref="branch_contacts"
        cols="8"
        class="pb-5"
      >
        <single-contact
          v-for="(contact, index) in branch_contacts"
          :ref="'branch_cont_' + index"
          :key="'branch-'+index"
          disabled
          :types="contact_types"
          :item="contact"
          :idx="index"
          :contacts-length="branch_contacts.length"
          @deleteContactItem="(idx) => deleteContactItem(idx, 'branch')"
          @addContactItem="addContactItem('branch')"
          @contactsUpdater="(value) => contactsUpdater(value, 'branch')"
        />
      </v-col>
    </v-row>
    <archive
      :show.sync="show"
      entity="recipient"
      @save="saveToArchive"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { getCountryNameByCode } from "@/helpers/catalogs";
import SingleContact from "@/components/uvr/single-contact.vue";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import Archive from "./archive.vue";
import cloneDeep from "lodash.clonedeep";
import { recipient as onRecipient } from "@/events/statistics/control";
import { highlightField } from "@/helpers/control";
import {checkContacts} from "@/components/uvr/regexp";

export default {
  components: { CustomDatePicker, SingleContact, Archive },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "uvr-recipient",
    addition: false,
    isIndividual: false,
    recipient: {
      ware_shipment_id: null,
      recipient_inn: "",
      recipient_name: "",
      recipient_branch_inn: "",
      recipient_branch_name: "",
      recipient_first_name: "",
      recipient_last_name: "",
      recipient_middle_name: "",
      recipient_identity_doc_number: "",
    },
    recipient_json: {
      country_letter: "",
      country_name: "",
      postal_index: "",
      region: "",
      city: "",
      street_house: "",
      house: "",
      room: "",
      branch_country_letter: "",
      branch_country_name: "",
      branch_postal_index: "",
      branch_region: "",
      branch_city: "",
      branch_street_house: "",
      branch_house: "",
      branch_room: "",
      aeo_country_letter: "",
      aeo_country_name: "",
      aeo_reg_number: "",
      personal_number: "",
      identity_doc_date: "",
    },
    contacts: [],
    branch_contacts:[],
    // поиск в справочнике
    recipients: [],
    loading: false,
    search: null,
    show: false,
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      selected: "uvr/getSelected",
      aeos: "uvr/getNsiAeos",
      countries: "catalogs/getNsiCountries",
      contact_types: "catalogs/getContactTypes",
      shipmentIndex:'uvr/getShipmentIndex',
      disabledView:'uvr/getVisibility'
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
    isImport(){
      return this.selected.type_declaration.direction === 1
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate:true
    },
    shipmentIndex(){
      this.setFields()
    },
    search(nv) {
      if (nv && Object.keys(nv).length) {
        this.updateFieldsOnSearch(nv);
        this.search = null;
        this.importers = [];
        this.forceUpload();
      }
    },
  },
  created() {
    onRecipient.subscribe(this.onControl);
  },
  beforeDestroy() {
    onRecipient.unsubscribe();
  },
  methods: {
    highlightField,
    onIndividualChange(isIndividual){
      if(isIndividual){
        this.addition = false
        this.clearBranchInfo()
      }
    },
    clearBranchInfo(){
      Object.keys(this.recipient_json).forEach(key => {
        if(['branch', 'aeo'].some(predicate => key.toString().startsWith(predicate))){
          this.recipient_json[key] = null
        }
      })
      this.recipient.recipient_branch_inn = null
      this.recipient.recipient_branch_name = null
      this.branch_contacts = []
    },
    onControl(data){
      this.addition = true
      this.$nextTick(() => {
        this.highlightField(data)
      })
    },
    forceUpload() {
      return this.uploadData()
        .then((res) => {
          this.$snackbar({
            text: "Обновлено [Получатель]",
            color: "green",
            top: false,
            right: false,
          });
          return res;
        })
        .catch((err) => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
          return Promise.reject(err);
        });
    },
    onMenuOpen() {
      if (this.hasChanges && this.fields_been_visited) {
        this.forceUpload().then(() => {
          this.fields_been_visited = false;
          this.hasChanges = false;
        });
      }
    },
    updateFieldsOnSearch(item) {
      this.recipient.recipient_name = item.name;
      this.recipient.recipient_inn = item.unp ? item.unp : item.inn;
      this.recipient.recipient_branch_inn = item.branch_inn
        ? item.branch_inn
        : item.branch_unp;
      this.recipient.recipient_branch_name = item.branch_name;

      Object.keys(this.recipient_json).forEach((key) => {
        if (key in item) {
          this.recipient_json[key] = item[key];
        }
      });
      this.recipient_json.street_house = item.street;
      this.recipient_json.branch_street_house = item.branch_street;

      // подгонка контактов
      const contacts = item.contacts;
      contacts.map((i) => {
        i.number = i.contact;
        delete i.contact;
      });

      const branch_contacts = item.branch_contacts;
      branch_contacts.map((i) => {
        i.number = i.contact;
        delete i.contact;
      });

      this.contacts = contacts;
      this.branch_contacts = branch_contacts
    },
    saveToArchive(own_name) {
      const payload = {
        id: null,
        division_id: this.divisionId,
        own_name: own_name,
        name: this.recipient.recipient_name,
        person_name: null,
        unp: this.recipient.recipient_inn,
        country_letter: this.recipient_json.country_letter,
        country_name: this.recipient_json.country_name,
        region: this.recipient_json.region,
        city: this.recipient_json.city,
        street: this.recipient_json.street_house,
        house: this.recipient_json.house,
        room: this.recipient_json.room,
        postal_index: this.recipient_json.postal_index,
        contacts: this.contacts,
        branch_name: this.recipient.recipient_branch_name,
        branch_unp: this.recipient.recipient_branch_inn,
        branch_country_letter: this.recipient_json.branch_country_letter,
        branch_country_name: this.recipient_json.branch_country_name,
        branch_region: this.recipient_json.branch_region,
        branch_city: this.recipient_json.branch_city,
        branch_street: this.recipient_json.branch_street_house,
        branch_house: this.recipient_json.branch_house,
        branch_room: this.recipient_json.branch_room,
        branch_postal_index: this.recipient_json.branch_postal_index,
        subject_doc_num: null,
        subject_doc_date: null,
        branch_contacts: this.branch_contacts,
      };

      // подгонка контактов
      payload.contacts.map((i) => {
        i.contact = i.number;
        delete i.number;
      });

      payload.branch_contacts.map((i) => {
        i.contact = i.number;
        delete i.number;
      });

      // проверка на резидента
      let url = "";
      if (payload.country_letter === "BY") {
        url = "division/saveResidentRaw";
      } else {
        url = "division/saveNoResidentRaw";
        payload.inn = payload.unp;
        payload.branch_inn = payload.branch_unp;
        delete payload.unp;
        delete payload.branch_unp;
      }

      this.$store
        .dispatch(url, payload)
        .then(() => {
          this.$snackbar({
            text: "Справочник обновлен",
            color: "blue",
            top: false,
            right: false,
          });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.$snackbar({
              text: err.response.data || "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          } else {
            this.$snackbar({
              text: "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          }
        });
    },
    addContactItem(type) {
      const contact ={
        code: "",
        name: "",
        number: "",
      }
      if(type === 'branch'){
        this.branch_contacts.push(contact);
      }else{
        this.contacts.push(contact);
      }
      this.readyToUpdate();
    },
    deleteContactItem(index, type) {
      if(type === 'branch'){
        this.branch_contacts.splice(index, 1)
      }else{
        this.contacts.splice(index, 1);
      }
      this.readyToUpdate();
    },
    readyToUpdate() {
      (this.hasChanges = true), (this.fields_been_visited = true);
    },
    contactsUpdater(payload, type) {
      if(type === 'branch'){
        this.branch_contacts[payload.index] = payload.cont_item;
      }else{
        this.contacts[payload.index] = payload.cont_item;
      }
      this.readyToUpdate();
    },
    setFields() {
      const {recipient} = this.selected.ware_shipments[this.shipmentIndex];
      const {recipient_json} = recipient
      const contacts = recipient_json?.contacts ?? [];
      const branch_contacts = recipient_json?.branch_contacts ?? [];
      this.contacts = cloneDeep(contacts);
      this.branch_contacts = cloneDeep(branch_contacts)

      Object.keys(this.recipient).forEach((key) => {
        if (key in recipient) {
          this.recipient[key] = recipient[key];
        }
      });

      Object.keys(this.recipient_json).forEach((key) => {
        if (key in recipient_json) {
          this.recipient_json[key] = recipient_json[key];
        }
      });

      this.isIndividual =
        this.recipient.recipient_last_name !== "" &&
        this.recipient.recipient_last_name !== null;
    },
    uploadData() {
      checkContacts.call(this, this.contacts)
      const recipient = this.getPreparedObject();
      return this.$store.dispatch("uvr/uploadShipmentBlockData", {
        name: "recipient",
        value: recipient,
      });
    },
    getPreparedObject() {
      const payload = {
        ware_shipment_id: this.recipient.ware_shipment_id,
        recipient_inn: this.isIndividual ? "" : this.recipient.recipient_inn,
        recipient_name: this.isIndividual ? "" : this.recipient.recipient_name,
        recipient_branch_inn: this.recipient.recipient_branch_inn,
        recipient_branch_name: this.recipient.recipient_branch_name,
        recipient_identity_doc_number: this.isIndividual
          ? this.recipient.recipient_identity_doc_number
          : "",
        recipient_last_name: this.isIndividual
          ? this.recipient.recipient_last_name
          : "",
        recipient_first_name: this.isIndividual
          ? this.recipient.recipient_first_name
          : "",
        recipient_middle_name: this.isIndividual
          ? this.recipient.recipient_middle_name
          : "",
        recipient_json: {
          ...this.recipient_json,
          personal_number: this.isIndividual
            ? this.recipient_json.personal_number
            : "",
          country_name:
            getCountryNameByCode(
              this.countries,
              this.recipient_json.country_letter
            ) || null,
          branch_country_name: getCountryNameByCode(
            this.countries,
            this.recipient_json.branch_country_letter
          ),
          aeo_country_name: getCountryNameByCode(
            this.countries,
            this.recipient_json.aeo_country_letter
          ),
          contacts: this.getContactsWithName(this.contacts),
          branch_contacts: this.getContactsWithName(this.branch_contacts)
        },
      };
      return convertEmptyStringsToNull(payload);
    },
    getContactsWithName(source) {
      return source.map((item) => {
        const { name } =
        this.contact_types.find((i) => i.code === item.code) ?? {};
        return {
          ...item,
          name,
        };
      });
    },
    fetchExporters(value) {
      if (value && value.length > 3) {
        this.loading = true;
        Promise.all([
          this.$store.dispatch("division/searchResident", value),
          this.$store.dispatch("division/searchNoResident", value),
        ])
          .then(([residents, noResidents]) => {
            this.loading = false;
            const results = [...residents.data, ...noResidents.data];
            const items = results.map((i) => {
              return {
                ...i,
                search: `${i.name}-${i.own_name}-${i.unp || i.inn}-${i.city}-${
                  i.street
                }`,
              };
            });
            this.recipients = [...items];
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
