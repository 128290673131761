<template>
  <tr
    :id="block_id"
    v-click-outside="triggerOnFocus"
  >
    <td>
      <v-text-field
        ref="number"
        :value="transport_item.number"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @input="(v) => transport_item.number = v.replace(' ', '')"
        @keypress.enter="showVehiclesCatalog"
      />
    </td>

    <td>
      <v-autocomplete
        ref="country_letter"
        v-model="transport_item.country_letter"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :items="countries"
        item-text="text"
        item-value="letterCode"
        :filter="filterBySearchField"
        @change="fillCountryName"
      />
    </td>
    <td>
      <v-autocomplete
        ref="vehicle_type"
        v-model="transport_item.vehicle_type"
        auto-select-first
        background-color="white"
        dense
        outlined
        hide-details="true"
        item-value="code"
        item-text="text"
        :items="vehicleTypes"
        :filter="filterBySearchField"
      />
    </td>
    <td class="d-flex">
      <v-btn
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0"
        @click="deleteTransport"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === itemsAmount - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addNewTransport"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {catalogValueSelected, showCatalog} from "@/events/statistics";
import {mapGetters} from "vuex";
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";
import {getCountryNameByCode} from "@/helpers/catalogs";

export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, highlightFieldsChild],
  props: {
    control: {
      required: true,
      type:Object
    },
    item: {
      required: true,
      type: Object,
    },
    itemsAmount: {
      required: true,
      type: Number,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    block_id: null,
    certificate_info:{
      certificate_number:null,
      certificate_date:null,
    },
    transport_item: {
      declaration_id:null,
      number:null,
      country_letter: null,
      country_name: null,
      vehicle_type: null,
    },
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      shipmentIndex: 'uvr/getShipmentIndex',
      selected:'uvr/getSelected',
      vehicleTypes: "catalogs/getNsiVehicleTypes",
      countries: "catalogs/getNsiCountries",
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    }
  },
  watch: {
    item: {
      handler() {
        this.block_id = `uvr-transport-item-${this.item.id}`;
        this.setFields()
      },
      immediate: true,
      deep: true,
    },
    transport_item:{
      handler(){
        this.updateTransport()
      },
      deep:true
    }
  },
  created() {
    catalogValueSelected.subscribe(this.updatesResolver, [
      'transport/number',
    ])
  },
  methods: {
    fillCountryName(countryLetterCode){
      this.transport_item.country_name = getCountryNameByCode(this.countries, countryLetterCode)
    },
    setFields(){
      Object.keys(this.transport_item).forEach(key => {
        if (key in this.transport_item) {
          this.transport_item[key] = this.item[key];
        }
      })
    },
    updateTransport(){
      this.$emit("updateTransport", {
        index: this.index,
        value: this.transport_item
      })
    },
    updatesResolver({id, field, code, item}) {
      if (id === this.index && code && field === 'transport/number') {
        const {number, country_letter, vehicle_type} = item
        this.transport_item = {
          ...this.transport_item,
          number,
          country_letter,
          vehicle_type,
        }
      }
    },
    showVehiclesCatalog() {
      showCatalog.trigger({
        type: "vehicles",
        id: this.index,
        search: this.transport_item.number,
        field: "transport/number"
      });
    },
    addNewTransport() {
      this.$emit("addNewTransport");
    },
    deleteTransport() {
      this.$emit("deleteTransport", this.index);
    },
  }
};
</script>

<style scoped>
.bg-gray {
  background-color: #ededed !important;
}

td {
  padding-left: 0 !important;
  border-bottom: none !important;
  padding-bottom: 10px !important;
}
</style>
