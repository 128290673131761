<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row
          class="my-auto mx-0 statistics-box"
        >
          <v-col
            :id="block_id"
            v-click-outside="triggerOnFocus"
            cols="6"
          >
            <div class="mb-4">
              <v-tooltip
                v-for="(btn, idx) in visibleActions"
                :key="idx"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    min-width="36px"
                    max-width="36px"
                    v-bind="attrs"
                    :disabled="btn.loading"
                    :loading="btn.loading"
                    v-on="on"
                    @click="callFunction(btn.action, { action: btn.action })"
                  >
                    <v-icon :color="btn.color || ''">
                      {{ btn.icon }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ btn.name }}</span>
              </v-tooltip>
            </div>
            <v-row>
              <v-col
                align-self="end"
                cols="4"
              >
                <v-text-field
                  :value="about_declaration.customs_store_license_number"
                  hide-details
                  disabled
                  outlined
                  dense
                  background-color="grey lighten-2"
                />
              </v-col>
              <v-col
                align-self="end"
                cols="3"
              >
                <custom-date-picker
                  ref="declaration_date"
                  v-model="entryDate"
                  disabled
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                  background-color="grey lighten-2"
                  @handleChange="suggestRecalc"
                />
              </v-col>
              <v-col
                align-self="end"
                cols="2"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="entryTime"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="entryTime"
                      readonly
                      hide-details
                      outlined
                      disabled
                      background-color="grey lighten-2"
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @change="readyToUpdate"
                    />
                  </template>
                  <v-time-picker
                    v-if="menu"
                    v-model="entryTime"
                    color="#5CB7B1"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menu.save(entryTime)"
                  />
                </v-menu>
              </v-col>
              <v-col
                align-self="end"
                cols="3"
              >
                <v-text-field
                  :value="about_declaration.keeper_name"
                  hide-details
                  disabled
                  outlined
                  dense
                  background-color="grey lighten-2"
                />
              </v-col>
            </v-row>
          </v-col>
          <document-control
            v-if="!pinned.includes('controls')"
            source="uvr/getControl"
            pin-name="controls"
            class="ml-2"
          >
            <template #action>
              <common-errors-action />
            </template>
          </document-control>
        </v-row>
      </v-col>
    </v-row>
    <component
      :is="activeComponent"
      :ref="getReferenceAsync"
      :show.sync="showComponent"
      :params="activeComponentParams"
    />
    <v-overlay
      :value="show_after_date"
    >
      <v-card
        v-click-outside="close"
        color="#EDEDED"
        width="500"
        light
      >
        <v-toolbar
          color="#333"
        >
          <div class="text-h5  white--text">
            Пересчитайте ТС товаров
          </div>
        </v-toolbar>
        <v-card-text class="pa-6">
          <v-btn
            class="mr-2 mb-2"
            @click="recalculateCustomsCostAndWarranty"
          >
            Рассчитать ТС и сумму гарантии
          </v-btn>
          <v-btn
            class="mr-2 mb-2"
            @click="show_after_date = false"
          >
            Не рассчитывать
          </v-btn>
        </v-card-text>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import CustomDatePicker from "@/components/shared/custom-date-picker";
import DocumentControl from "@/components/shared/document-control.vue";
import CommonErrorsAction from "@/components/uvr/controls/common-errors-action.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import blockVisibility from '@/mixins/block-visibility'
import {
  displayControlResponse,
  downloadFile,
  downloadXml,
  getFilenameFromContentDisposition,
  getSignedDocument,
  getXmlString,
  NAMESPACE_REQUIRED,
  performDocumentControl, PTO_REQUIRED,
  SELECTED_REQUIRED,
  sendToEclient,
  USER_REQUIRED,
  validateRequiredParams,
} from "@/helpers/documents";
import {about_declaration as onAboutDeclaration} from "@/events/statistics/control";
import ToolboxMixin from "@/mixins/toolbox.mixin";
import {eventBus} from "@/bus.js";
import moment from "moment/moment";
import {convertEmptyStringsToNull} from "@/helpers/objects";


export default {
  components: {
    CustomDatePicker,
    DocumentControl,
    CommonErrorsAction,
  },
  mixins: [blockAutoUpdate, ToolboxMixin, blockVisibility],
  data: () => ({
    block_id: "uvr-about-declaration",
    selectedPto:null,
    namespace: "uvr",
    menu:false,
    entryDate:null,
    entryTime:null,
    actions: [
      {
        visible: true,
        loading: false,
        icon: "mdi-upload",
        action: "sendDocument",
        name: "Отправить в ИСТО",
      },
      {
        visible: true,
        icon: "mdi-file-pdf-box",
        action: "downloadPdf",
        name: "Скачать PDF",
      },
      {
        visible: true,
        loading: false,
        icon: "mdi-file-xml-box",
        action: "downloadXml",
        name: "Скачать XML",
      },
      {
        visible: false,
        loading: false,
        icon: "",
        action: "showXsdControlResponse",
        name: "Контроль XSD",
        component: () =>
          import("@/components/documents/xsd-control-response.vue"),
      },
      {
        visible: false,
        loading: false,
        icon: "",
        action: "showDocumentControlResponse",
        name: "Контроль FLK",
        component: () =>
          import("@/components/shared/document-control-response.vue"),
      },
      {
        visible: false,
        icon: "",
        action: "showDocumentControlPto",
        name: "Контроль ПТО",
        component: () => import("@/components/shared/document-control-pto.vue"),
      },
    ],
    about_declaration: {
      declaration_id: null,
      number: null,
      full_number: null,
      customs_store_license_number: null,
      entry_time: null,
      keeper_name: null,
    },
    show_after_date: false,
  }),
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
      pinned: "ui/getPinned",
      user: "auth/getUser",
      requests: "uvr/getRequests",
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onAboutDeclaration.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onAboutDeclaration.unsubscribe();
  },
  methods: {
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    validateRequiredParams,
    performDocumentControl,
    displayControlResponse,
    getXmlString,
    getSignedDocument,
    sendToEclient,
    parseEntryTime(){
      const dateTime = moment(this.about_declaration.entry_time)
      this.entryTime = dateTime.isValid() ? dateTime.format('HH:mm') : undefined
      this.entryDate = dateTime.isValid() ? dateTime.format('YYYY-MM-DD') : undefined
    },
    fetchDocument(){
      return this.$store.dispatch("uvr/getDocumentById", this.selected.id)
    },
    sendDocument(){
      this.setLoading('sendDocument', true)
      this.validateRequiredParams({
        ...SELECTED_REQUIRED,
        ...NAMESPACE_REQUIRED,
        ...USER_REQUIRED,
        ...PTO_REQUIRED
      })
        .then(this.performDocumentControl)
        .then(this.displayControlResponse)
        .then(this.showDocumentControlPto)
        .then(this.updateSelectedPto)
        .then(this.getXmlString)
        .then(this.getSignedDocument)
        .then(this.sendToEclient)
        .catch(() => {})
        .finally(() => this.setLoading('sendDocument', false))
    },
    suggestRecalc() {
      this.show_after_date = true
    },
    close() {
      this.show_after_date = false
    },
    recalculateCustomsCostAndWarranty() {
      eventBus.$emit("recalculateCustomsCost");
      this.show_after_date = false
    },
    setFields() {
      const {about_declaration} = this.selected || {};
      for (let key in about_declaration) {
        if (key in this.about_declaration) {
          this.about_declaration[key] = about_declaration[key];
        }
      }
      this.parseEntryTime()
    },
    downloadXml() {
      this.$store.dispatch("uvr/getXml", this.selected.id).then((res) => {
        downloadXml(res.data, getFilenameFromContentDisposition(res));
      });
    },
    getPreparedAboutDeclaration(){
      const time = this.entryTime ?? "00:00"
      const entry_time = this.entryDate ? moment(this.entryDate + ' ' + time, 'YYYY-MM-DD HH:mm').toISOString() : null
      const about = {
        ...this.about_declaration,
        entry_time
      }
      return convertEmptyStringsToNull(about)
    },
    uploadData() {
      return this.$store.dispatch("uvr/uploadBlockData", {
        name: "about_declaration",
        value: this.getPreparedAboutDeclaration(),
      });
    },
    downloadPdf() {
      this.$store
        .dispatch("uvr/downloadPdf", this.selected.id)
        .then((res) => {
          downloadFile({
            data: res.data,
            type: "application/pdf",
            name: getFilenameFromContentDisposition(res),
          });
        })
        .catch(() => this.$error('Ошибка'))
    }
  },
};
</script>

<style scoped>
.burger span {
  display: block;
  background: #333333;
  height: 2px;
  width: 100%;
  margin: 10px 0;
}

.slash {
  display: block;
  text-align: center;
  height: 100%;
  margin: auto 0;
}
</style>
