<template>
  <v-col
    cols="12"
    class="goods-description__block pt-0"
  >
    <div class="goods-description__text-wrapper">
      <v-textarea
        :value="item.description"
        placeholder="Описание"
        :counter="1000"
        :rows="4"
        no-resize
        loading="false"
        full-width
        hide-details="auto"
        class="description-text textarea-bottom-solid"
        :class="{
          'textarea-error': item.description?.length > 1000
        }"
        @input="(nv) => $emit('input', nv)"
      />
      <div
        v-show="!add_goods_by_list"
        class="goods-description__action"
      >
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              tabindex="-1"
              min-width="36px"
              max-width="36px"
              color="#5CB7B1"
              v-on="on"
            >
              <v-icon>mdi-tune</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="copyToAll('description', item.description )">
              Применить ко всем товарам
            </v-list-item>
            <v-list-item @click="copy('description', item.description, 'apply')">
              Применить к выбранным товарам
            </v-list-item>
            <v-list-item @click="googleImage(item.description)">
              <v-list-item-title>
                <v-icon>mdi-magnify</v-icon>
                Поиск в  Google
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import ApplyParamsMixin from '@/mixins/apply-params.mixin'
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import {googleImage} from '@/helpers/text'
import CopyFieldMixin from "@/mixins/copy-field.mixin";
export default {
  name: "GoodsDescription",
  mixins:[ApplyParamsMixin, filterBySearchFieldMixin, CopyFieldMixin],
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    add_goods_by_list: false,
  }),
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
    }),
  },
  methods: {
    googleImage,
  },
};
</script>
<style scoped>
.goods-description__block, .goods-description__text-wrapper {
  position: relative;
}
.goods-description__action {
  position: absolute;
  right: 1%;
  top: 20px;
  z-index: 2;
}
.textarea-bottom-solid {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  margin: 10px 0 0 0;
  padding: 10px 50px 10px 10px;
}
.textarea-error{
  border: 2px solid red;
}
</style>
