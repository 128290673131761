<template>
  <v-col
    :id="block_id"
    v-click-outside="triggerOnFocus"
    cols="12"
  >
    <div
      class="statistics-box pa-4"
      :class="{'blocked-box' : blocked_visibility}"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <div class="font-weight-bold d-flex align-center">
            Предшествующие документы
          </div>
        </v-col>
        <v-col
          v-if="!previous_reg_numbers_json.length"
          cols="12"
        >
          <v-btn
            text
            class="elevation-0 px-0"
            @click="addRegNumber"
          >
            <v-icon>mdi-plus</v-icon>Добавить
          </v-btn>
        </v-col>
        <template v-else>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-simple-table
              ref="common"
              class="bg-gray"
              fixed-header
              dense
            >
              <template #default>
                <thead>
                  <tr>
                    <th width="80px">
                      Тип
                    </th>
                    <th width="250px">
                      Номер
                    </th>
                    <th width="150px">
                      Дата
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <previous-reg-number
                    v-for="(item, index) of previous_reg_numbers_json"
                    :key="`regnum-${index}`"
                    :index="index"
                    :item="item"
                    :items="previous_reg_numbers_json"
                    @add="addRegNumber"
                    @remove="removeRegNumber"
                    @update="updateRegNumber"
                  />
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </template>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { mapGetters } from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { previous_reg_number as onPreviousRegNumber } from "@/events/statistics/control";
import { highlightField } from "@/helpers/control";
import blockVisibility from '@/mixins/block-visibility';
import cloneDeep from "lodash.clonedeep";
import PreviousRegNumber from "@/components/uvr/previous-reg-numbers/previous-reg-numbers.vue";

export default {
  components: { PreviousRegNumber},
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, blockVisibility],
  data: () => ({
    block_id: "uvr-previous-reg-number",
    previous_reg_number:{
      declaration_id:null
    },
    previous_reg_numbers_json:[]
  }),
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
      user:'auth/getUser',
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onPreviousRegNumber.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onPreviousRegNumber.unsubscribe();
  },
  methods: {
    addRegNumber(){
      const regNumber = {
        type:null,
        number:null,
        date:null,
      }
      this.previous_reg_numbers_json.push(regNumber)
      this.readyToUpdate()
    },
    updateRegNumber({index, value}){
      this.previous_reg_numbers_json.splice(index, 1, value)
      this.readyToUpdate()
    },
    removeRegNumber(index){
      this.previous_reg_numbers_json.splice(index, 1)
      this.readyToUpdate()
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    highlightField,
    setFields() {
      const previous_reg_number = this.selected.previous_reg_number;
      this.previous_reg_number.declaration_id = previous_reg_number.declaration_id
      this.previous_reg_numbers_json = cloneDeep(previous_reg_number.previous_reg_numbers_json)
    },
    uploadData() {
      const previous_reg_number = this.convertEmptyStringsToNull({
        ...this.previous_reg_number,
        previous_reg_numbers_json: this.previous_reg_numbers_json
      });
      return this.$store.dispatch("uvr/uploadBlockData", {
        name: "previous_reg_number",
        value: previous_reg_number,
      })
    },
  },
};
</script>
<style scoped>
.bg-gray {
  background-color: #ededed !important;
}
thead tr th {
  padding-left: 0 !important;
  background-color: #ededed !important;
  box-shadow: none !important;
}
</style>
