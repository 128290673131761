<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="6">
        <exporter />
      </v-col>
      <v-col cols="6">
        <recipient />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Exporter from '@/components/uvr/contragents/exporter.vue'
import Recipient from '@/components/uvr/contragents/recipient.vue'

export default {
  components: {
    Exporter, Recipient
  },
}
</script>
