import { render, staticRenderFns } from "./previous-reg-numbers.vue?vue&type=template&id=f330a882&scoped=true"
import script from "./previous-reg-numbers.vue?vue&type=script&lang=js"
export * from "./previous-reg-numbers.vue?vue&type=script&lang=js"
import style0 from "./previous-reg-numbers.vue?vue&type=style&index=0&id=f330a882&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f330a882",
  null
  
)

export default component.exports