<template>
  <div v-if="showNavigation">
    <div class="shipment-pagination">
      <div class="shipment-pagination__buttons">
        <template v-if="shipmentIndex !== null">
          <v-btn
            v-for="page in pages"
            :key="page"
            class="d-block mb-1"
            min-width="32px"
            min-height="32px"
            width="32px"
            height="32px"
            :color="page === currentPage ? 'teal lighten-2': 'grey lighten-2'"
            @click="selectPage(page)"
          >
            {{ page }}
          </v-btn>
        </template>

        <v-btn
          min-width="32px"
          min-height="32px"
          width="32px"
          height="32px"
          class="d-block mb-1"
          @click="addShipment"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          v-if="shipmentIndex !== null"
          class="d-block mb-1"
          min-width="32px"
          min-height="32px"
          width="32px"
          height="32px"
          @click="deleteShipment"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </div>
    </div>
    <delete-shipment-modal
      :show.sync="show"
      module="uvr"
    />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import DeleteShipmentModal from "@/components/documents/delete-shipment-modal.vue";

export default {
  components: {DeleteShipmentModal},
  data:() => ({
    assigned:false,
    show:false,
  }),
  computed:{
    ...mapGetters({
      selected:"uvr/getSelected",
      shipmentIndex:'uvr/getShipmentIndex',
      requests:"uvr/getRequests"
    }),
    showNavigation(){
      return true
    },
    pages(){
      return this.selected?.ware_shipments?.length ?? 0
    },
    currentPage(){
      return this.shipmentIndex === null ? 0 : this.shipmentIndex + 1
    },
  },
  watch:{
    selected:{
      handler(){
        this.assignDefault()
      },
      deep:true,
    }
  },
  methods:{
    assignDefault(){
      if(this.selected.ware_shipments?.length && !this.assigned){
        this.$store.commit('uvr/SET_SHIPMENT_INDEX',0)
        this.assigned = true
      }
    },
    addShipment(){
      this.$store.dispatch('uvr/addShipment').catch(() => {
        this.$snackbar({text:'Ошибка',color:'red', top:false})
      })
    },
    deleteShipment(){
      this.show = true
    },
    selectPage(value){
      Promise.all(this.requests).then(() => {
        this.$store.commit('uvr/SET_SHIPMENT_INDEX', value - 1)
      })
    }
  }
}
</script>
<style scoped>
.shipment-pagination{
  position: fixed;
  z-index: 5;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

</style>
