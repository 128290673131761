<template>
  <v-col
    :id="block_id"
    v-click-outside="triggerOnFocus"
    cols="12"
  >
    <div
      class="statistics-box pa-4"
      :class="{'blocked-box' : blocked_visibility}"
    >
      <v-row>
        <v-col cols="9">
          <div class="font-weight-bold d-flex align-center mt-2">
            Водитель
          </div>
        </v-col>
        <v-col
          cols="3"
          class="d-flex justify-end align-center"
        >
          <v-autocomplete
            :value="search"
            class="ml-16"
            :loading="loading"
            :readonly="loading"
            :items="drivers"
            item-text="name"
            :filter="filterBySearchField"
            placeholder="Поиск по паспорту"
            auto-select-first
            return-object
            append-icon="mdi-magnify"
            tabindex="-1"
            background-color="white"
            dense
            outlined
            hide-details="true"
            @change="onSearchChange"
            @update:search-input="fetchDrivers"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="1"
        >
          <label>
            Страна
            <v-autocomplete
              ref="country_letter"
              v-model="driver_json.country_letter"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :filter="filterBySearchField"
              :items="countries"
              item-text="text"
              item-value="letterCode"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Фамилия
            <v-text-field
              ref="driver_last_name"
              v-model="driver.driver_last_name"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Имя
            <v-text-field
              ref="driver_first_name"
              v-model="driver.driver_first_name"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Отчество
            <v-text-field
              ref="driver_middle_name"
              v-model="driver.driver_middle_name"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          v-if="!contacts.length"
          class="d-flex align-end justify-end mb-1"
          :offset="3"
        >
          <v-btn
            dense
            class="elevation-0"
            @click="addContactItem"
          >
            <v-icon>mdi-plus</v-icon> Контакт
          </v-btn>
        </v-col>
        <v-col
          v-if="contacts.length"
          cols="4"
          offset="1"
        >
          <single-contact
            v-for="(contact, index) in contacts"
            :ref="'cont_' + index"
            :key="index"
            :types="contact_types"
            :item="contact"
            :idx="index"
            :contacts-length="contacts.length"
            @deleteContactItem="deleteContactItem"
            @addContactItem="addContactItem"
            @contactsUpdater="contactsUpdater"
          />
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { mapGetters } from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { getCountryNameByCode } from "@/helpers/catalogs";
import { driver as onDriver } from "@/events/statistics/control";
import { highlightField } from "@/helpers/control";
import blockVisibility from '@/mixins/block-visibility';
import {checkContacts, phoneRegExp} from "@/components/uvr/regexp";
import SingleContact from "@/components/uvr/single-contact.vue";
import cloneDeep from "lodash.clonedeep";

export default {
  components: {SingleContact},
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, blockVisibility],
  data: () => ({
    block_id: "uvr-driver",
    loading:false,
    search: null,
    drivers:[],
    driver: {
      declaration_id: null,
      driver_first_name: "",
      driver_last_name: "",
      driver_middle_name: "",
    },
    driver_json: {
      country_letter: "",
      country_name: "",
    },
    contacts:[]
  }),
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
      contact_types: "catalogs/getContactTypes",
      user:'auth/getUser',
      countries:'catalogs/getNsiCountries',
    }),
    wrongPhoneNumber(){
      return this.contacts.code === 'TE' ? !phoneRegExp.test(this.contacts.number) : false
    },
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onDriver.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onDriver.unsubscribe();
  },
  methods: {
    addContactItem() {
      this.contacts.push({
        code: "",
        name: "",
        number: "",
      });
      this.readyToUpdate();
    },
    deleteContactItem(index) {
      this.contacts.splice(index, 1);
      this.readyToUpdate();
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    onSearchChange(nv){
      this.updateFieldsOnSearch(nv);
      this.search = null;
      this.drivers = [];
      this.forceUpload();
    },
    contactsUpdater(payload) {
      this.contacts[payload.index] = payload.cont_item;
      this.readyToUpdate();
    },
    getContactsWithName(source) {
      return source.map((item) => {
        const { name } =
        this.contact_types.find((i) => i.code === item.code) ?? {};
        return {
          ...item,
          name,
        };
      });
    },
    saveDriver(){
      const country_name = getCountryNameByCode(this.countries, this.driver_json.country_letter)
      const division_id = this.user.user.activeDivisionId
      const contacts = this.getContactsWithName([{
        code:this.contacts.code,
        name:this.contacts.name,
        contact: this.contacts.number
      }])
      const payload = {
        id: null,
        country_letter: this.driver_json.country_letter,
        identity_doc_code:  null,
        identity_doc_number:  null,
        identity_doc_date:  null,
        first_name: this.driver.driver_first_name,
        last_name: this.driver.driver_last_name,
        middle_name: this.driver.driver_middle_name,
        user_id: this.user.user.id,
        country_name,
        division_id,
        contacts
      }
      this.$store.dispatch("catalogs/saveDriver", payload)
    },
    highlightField,
    updateFieldsOnSearch(item) {
      this.driver = {
        ...this.driver,
        driver_first_name: item.first_name,
        driver_last_name: item.last_name,
        driver_middle_name: item.middle_name,
      }
      this.driver_json = {
        ...this.driver_json,
        country_letter: item.country_letter,
        country_name: item.country_name,
      }
      this.contacts = item.contacts
    },
    forceUpload() {
      return this.uploadData()
        .then((res) => {
          this.resetTriggers()
          this.$snackbar({
            text: "Обновлено [Водитель]",
            color: "green",
            top: false,
            right: false,
          });
          return res;
        })
        .catch((err) => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
          return Promise.reject(err);
        });
    },
    fetchDrivers(value){
      if (value && value.length > 3) {
        this.loading = true;
        this.$store
          .dispatch("catalogs/searchDriver", {
            value,
            divisionId: this.divisionId,
          })
          .then((res) => {
            this.loading = false;
            const items = res.data.map((i) => {
              const {identity_doc_number} = i
              return {
                ...i,
                search: identity_doc_number,
                name: identity_doc_number
              };
            });
            this.drivers = [...items];
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    setFields() {
      const driver = this.selected.driver;
      const driver_json = this.selected.driver.driver_json;

      this.contacts = cloneDeep(driver_json.contacts ?? [])

      Object.keys(this.driver).forEach((key) => {
        if (key in driver) {
          this.driver[key] = driver[key];
        }
      });

      Object.keys(this.driver_json).forEach((key) => {
        if (key in driver_json) {
          this.driver_json[key] = driver_json[key];
        }
      });


    },
    uploadData() {
      checkContacts.call(this, this.contacts)
      const country_name = getCountryNameByCode(
        this.countries,
        this.driver_json.country_letter
      );
      const payload = {
        ...this.driver,
        driver_json: {
          ...this.driver_json,
          country_name,
          contacts: this.contacts
        },
      };
      const driver = this.convertEmptyStringsToNull(payload);
      return this.$store.dispatch("uvr/uploadBlockData", {
        name: "driver",
        value: driver,
      }).then((res) => {
        this.saveDriver()
        return res
      });
    },
  },
};
</script>
