<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col cols="6">
        <div
          class="statistics-box d-flex"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <v-col
            cols="4"
            align-self="end"
          >
            <label>
              Цель
              <v-autocomplete
                ref="purpose_code"
                v-model="type_declaration.purpose_code"
                :items="purposePlacements"
                item-value="code"
                item-text="search"
                auto-select-first
                outlined
                hide-details="auto"
                dense
                background-color="white"
                :filter="filterBySearchField"
              />
            </label>
          </v-col>
          <v-col
            cols="3"
            align-self="end"
          >
            <label>
              Направление
              <v-autocomplete
                ref="direction"
                v-model="type_declaration.direction"
                :items="directions"
                auto-select-first
                outlined
                hide-details="auto"
                dense
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="5"
            align-self="end"
          >
            <label>
              Вес брутто / нетто</label>
            <div class="d-flex">
              <v-text-field
                ref="gross_weight"
                :value="weight"
                disabled
                outlined
                background-color="grey lighten-2"
                dense
                class="mr-2"
                hide-details="auto"
                suffix="КГ"
              />
              <v-btn
                class="elevation-0"
                min-width="38px"
                width="38px"
                color="teal lighten-2"
                @click="recalculateWeight"
              >
                <v-icon>mdi-calculator</v-icon>
              </v-btn>
            </div>
          </v-col>
        </div>
      </v-col>

      <v-col
        cols="6"
      >
        <div class="statistics-box d-flex">
          <v-col
            cols="4"
          >
            <label>
              А Регистрационный номер
              <v-menu offset-x>
                <template #activator="{ on }">
                  <v-icon
                    tabindex="-1"
                    dense
                    class="mx-1"
                    small
                    v-on="on"
                  >mdi-tune
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item

                    @click="disabled = !disabled"
                  >Ручное заполнение
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-text-field
                ref="reg_number"
                v-model="registration_details.reg_num_a"
                v-mask="'########/######/#######'"
                outlined
                dense
                hide-details="auto"
                :disabled="disabled"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                class="p-0"
              />
            </label>
          </v-col>
          <v-col cols="4">
            <label>ФИО заявителя</label>
            <v-text-field
              ref="filler_name"
              v-model="registration_details.filler_name"
              outlined
              dense
              hide-details="auto"
              disabled
              background-color="grey lighten-2"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="4"
          >
            <label>
              УНП заявителя
              <v-text-field
                ref="unp"
                v-model="registration_details.unp"
                outlined
                dense
                hide-details="auto"
                :disabled="disabled"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                class="p-0"
              />
            </label>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import blockVisibility from '@/mixins/block-visibility'
import {
  type_declaration as onTypeDeclaration,
  registration_details as onRegistrationDetails,
} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {getWaresParamTotal} from "@/helpers/math";

export default {
  mixins: [blockAutoUpdate, blockVisibility, filterBySearchFieldMixin],
  data() {
    return {
      block_id: "uvr-main-info",
      disabled: true,
      menu:false,
      entryDate:null,
      entryTime:null,
      directions:[
        {
          text:"Вывоз",
          value:0
        },
        {
          text:"Ввоз",
          value:1
        },
      ],
      type_declaration: {
        declaration_id: null,
        direction: null,
        purpose_code: null,
        gross_weight: null,
        net_weight: null
      },
      registration_details: {
        declaration_id: null,
        reg_num_a: null,
        reg_num_b: null,
        filler_name:null,
        unp:null
      },
    };
  },
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
      customsPoints: 'catalogs/getNsiCustomsPoints',
      purposePlacements: "catalogs/getNsiPurposePlacements"
    }),
    weight(){
      return `${this.type_declaration.gross_weight ?? 0}/${this.type_declaration.net_weight ?? 0}`
    }
  },
  watch: {
    selected: {
      handler(){
        this.setFields();
        this.disabled = true
      },
      deep: true
    },
  },
  created() {
    onTypeDeclaration.subscribe(this.onControl);
    onRegistrationDetails.subscribe(this.onControl);
  },
  beforeDestroy() {
    onTypeDeclaration.unsubscribe();
    onRegistrationDetails.unsubscribe();
  },
  methods: {
    recalculateWeight(){
      this.type_declaration.gross_weight = this.selected.ware_shipments.reduce((prev, current) => {
        return prev + getWaresParamTotal(current.wares, "gross_weight");
      }, 0)
      this.type_declaration.net_weight = this.selected.ware_shipments.reduce((prev, current) => {
        return prev + getWaresParamTotal(current.wares, "net_weight");
      }, 0)
      this.readyToUpdate()
    },
    highlightField,
    onControl({path}){
      highlightField.call(this, {path})
    },
    setFields() {
      const {type_declaration} = this.selected || {};
      const {registration_details} = this.selected || {};
      Object.keys(this.type_declaration).forEach(key => {
        if(key in type_declaration){
          this.type_declaration[key] = type_declaration[key];
        }
      })

      Object.keys(this.registration_details).forEach(key => {
        if(key in registration_details){
          this.registration_details[key] = registration_details[key];
        }
      })
    },
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    async uploadData() {
      const type_declaration = this.convertEmptyStringsToNull(this.type_declaration);
      const registration_details = this.convertEmptyStringsToNull(this.registration_details)
      await this.$store.dispatch("uvr/uploadBlockData", {
        name: "type_declaration",
        value: type_declaration,
      });
      await this.$store.dispatch("uvr/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });
      this.disabled = true

    },
  },
};
</script>
<style scoped>
  label{
    margin: 0;
  }
</style>
