<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          :id="block_id"
          v-click-outside="triggerOnFocus"
          class="statistics-box uvr-transport-list pa-4"
          :class="{'blocked-box' : disabledView}"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
              :class="{'blocked-box-exclude':disabledView }"
            >
              <div
                class="font-weight-bold d-flex align-center"
              >
                Транспортные средства
              </div>
            </v-col>

            <v-col
              v-if="transportItems.length"
              ref="common"
              cols="5"
              class="pb-0"
            >
              <v-simple-table
                class="bg-gray"
                fixed-header
                dense
              >
                <template #default>
                  <thead>
                    <tr>
                      <th width="150px">
                        Номер ТС
                      </th>
                      <th width="150px">
                        Страна рег.
                      </th>
                      <th>
                        Код вида ТС
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <transport-item
                      v-for="(item, index) in transportItems"
                      :key="index"
                      :item="item"
                      :items-amount="transportItems.length"
                      :index="index"
                      :control="transportControlPath"
                      @addNewTransport="addNewTransport"
                      @deleteTransport="deleteTransport"
                      @updateTransport="updateTransport"
                    />
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              v-if="!transportItems.length"
              ref="common"
              cols="9"
              align-self="center"
            >
              <v-btn
                text
                class="px-0"
                @click="addNewTransport"
              >
                <v-icon>mdi-plus</v-icon>
                Добавить ТС
              </v-btn>
            </v-col>

            <v-col
              cols="3"
              :offset="transportItems.length ? 4 : 0"
            >
              <label>
                Книжка МДП
                <v-text-field
                  v-model="about_transport.tir_serial_number"
                  background-color="white"
                  dense
                  outlined
                  hide-details="true"
                />
              </label>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <component
      :is="activeComponent"
      namespace="transit"
      :show.sync="show"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import TransportItem from "@/components/uvr/transport/transport-item.vue";
import {getScrollOffset, highlightField} from "@/helpers/control";
import { about_transport as onAboutTransport, transports as onTransports } from "@/events/statistics/control";
import blockVisibility from '@/mixins/block-visibility'
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import cloneDeep from "lodash.clonedeep";

export default {
  components: { TransportItem },
  mixins: [blockAutoUpdate, blockVisibility, filterBySearchFieldMixin],
  data: () => ({
    block_id: "uvr-about-transport",
    activeComponent:null,
    show:false,
    transportItems: [],
    about_transport: {
      border_transport_type: null,
      container_indicator: null,
      declaration_id: null,
      tir_serial_number: null
    },
    container_types: [
      {
        text: '0 - без контейнера',
        value: false,
      },
      {
        text: '1 - с контейнером',
        value: true,
      },
    ],
    transportControlPath: {
      path: null
    }
  }),
  computed: {
    ...mapGetters({
      selected: "uvr/getSelected",
      nsiTransportMarks: "uvr/getNsiTransportMarks",
      disabledView: "uvr/getVisibility"
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onAboutTransport.subscribe(this.onAboutTransportControl);
    onTransports.subscribe(this.onTransportControl)
  },
  beforeDestroy() {
    onAboutTransport.unsubscribe();
    onTransports.unsubscribe()
  },
  methods: {
    onAboutTransportControl(path){
      const docs = document.querySelector(".uvr-transport-list");
      const offset = getScrollOffset();
      this.$scrollTo(docs, 200, {
        force: true,
        offset,
        onDone: () => {
          highlightField.call(this, path)
        },
      });
    },
    onTransportControl(path){
      const docs = document.querySelector(".uvr-transport-list");
      const offset = getScrollOffset();
      this.$scrollTo(docs, 200, {
        force: true,
        offset,
        onDone: () => {
          this.transportControlPath = path
        },
      });
    },
    filterByCode(item, queryText) {
      return (
        item.code.indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    uploadData() {
      const about_transport = this.convertEmptyStringsToNull({
        ...this.about_transport,
        transports_json: this.transportItems
      });
      return this.$store.dispatch("uvr/uploadBlockData", {
        name: "about_transport",
        value: about_transport,
      });
    },
    addNewTransport() {
      const transport = {
        number:null,
        country_letter:null,
        country_name:null,
        vehicle_type:null
      }
      this.transportItems.push(transport)
      this.readyToUpdate()
    },
    updateTransport({index, value}){
      this.transportItems.splice(index, 1, value)
      this.readyToUpdate()
    },
    deleteTransport(index) {
      this.transportItems.splice(index, 1)
      this.readyToUpdate()
    },
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    setFields() {
      const about_transport = this.selected.about_transport;
      Object.keys(this.about_transport).forEach(key => {
        if(key in about_transport){
          this.about_transport[key] = about_transport[key]
        }
      })
      this.transportItems = cloneDeep(about_transport.transports_json ?? []) ;
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #ededed !important;
}
thead tr th {
  padding-left: 0 !important;
  background-color: #ededed !important;
  box-shadow: none !important;
}
</style>
