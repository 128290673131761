<template>
  <v-overlay :value="show">
    <v-card
      class="mx-auto pa-5"
      max-width="500px"
      color="white"
      light
    >
      <v-card-title class="mb-4">
        Cохранить организацию в справочник
      </v-card-title>
      <v-card-subtitle class="pb-1">
        Укажите псевдоним, который будет использоваться при поиске.
      </v-card-subtitle>

      <v-text-field
        v-model="own_name"
        placeholder="Введите название"
        class="px-4"
        dense
        outlined
        hide-details="true"
      />
      <slot />
      <v-card-actions class="pa-4 justify-between">
        <v-btn
          dark
          color="red"
          class="px-5"
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          dark
          color="green"
          class="px-5"
          @click="save"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    entity:{
      required:true,
      type:String
    }
  },
  data: () => ({
    own_name: ''
  }),
  computed:{
    ...mapGetters({
      selected:'uvr/getSelected',
      shipmentIndex:'uvr/getShipmentIndex'
    })
  },
  watch:{
    show(nv){
      if(nv === false){
        this.unset()
      }else{
        if(this.entity && this.shipmentIndex){
          const shipment = this.selected?.ware_shipments[this.shipmentIndex]
          this.own_name = shipment[this.entity][`${this.entity}_name`] ?? ""
        }
      }
    }
  },
  methods: {
    unset(){
      this.own_name= ""
    },
    close() {
      this.$emit("update:show", false);
    },
    save() {
      this.$emit("save", this.own_name);
      this.close();
    },
  },
};
</script>